<template>
  <v-main class="fill-height">
    <div class="fill-height overflow-y-auto">
      <transition
        name="fade"
        mode="out-in"
        duration="80"
      >
        <router-view>
          <template v-slot:map>
            <slot name="map" />
          </template>
        </router-view>
      </transition>

      <core-flash />
    </div>
  </v-main>
</template>

<script>
  export default {
    components: {
      CoreFlash: () => import('@/components/core/Flash'),
    },
  };
</script>
